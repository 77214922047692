.published-pg-header{
    height: 70px;
    background-color: white;
    padding-left: 50px;
}
.published-body{
    background-color: #E5E5E5;
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 70px;
    overflow: hidden;
}
.published{
    overflow: hidden;
}
.published-header{
    font-size: 24px;
    font-weight: 600;
}
.share-work{
    font-size: 20px;
    margin-top: 40px;
    font-weight: 500;
}
.publish-form-group{
    margin-top: 40px;
}
.published-inpt{
    width: 60%;
    height: 50px;
    border: 1px solid lightgray;
    background-color: #E5E5E5;
}
.btn-copy-link{
    height: 50px;
    width: 10%;
    color: white;
    background-color: #3B5998;
    border: 0px;
    font-size: 16px;
    margin-left: 20px;
}
.social-links{
    margin-top: 60px;
}
.fabs{
    margin-right: 20px;
    color: white;
}
.days-box{
    min-height: 120px;
    background-color: white;
    margin-right: 100px;
    margin-left: 100px;  
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
    width: fit-content;
    margin: auto;
    margin-top: 50px;
}
.time{
    margin-right: 30px;
    font-size: 18px;
    font-weight: 600;
}
.published-btns{
    margin-top: 70px;
    margin-left: 100px;
    margin-right: 100px;
}
.btn-memorialize{
    width: 45%;
    height: 45px;
    margin-right: 20px;
    background-color: black;
    color: white;
    border: 0px;
}
.btn-view-work{
    width: 45%;
    height: 45px;
    background-color: #ABABAB;
    color: white;
    border: 0px;
}
.memorialize-modal{
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}
.memorialize-modal-header{
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 40px;
}
.memorialize-modal-text{
    /*font-size: 18px;*/
    font-size: 19.5px;
    font-weight: 500;
    margin-bottom: 20px;
}
.cost-box{
    margin-top: 30px;
    font-weight: 700;
}
.cost-label{
    font-size: 27px;
}
.cost-price{
    font-size: 24px;
}
.memorialize-modal-btns{
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
}
.btn-IWTMMH, .btn-memorialize-cancel{
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    border: 1px solid black;
}
.btn-IWTMMH{
    background-color: black;
    color: white;
    
}
#btn-pay{
    background-color: red;
}
.btn-memorialize-cancel{
    background-color: white;
    color: black;
    font-weight: 600;
}
#authorship-form-group{
    padding-left: 30px;
    padding-right: 30px;
}
#authorship-modal{
    padding-top: 10px;
}
.skip{
    margin-bottom: 50px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}
.payment-modal{
    min-height: 100px;
}
#checkout-header{
    margin-bottom: 0px;
}
.checkout-modal{
    padding-top: 50px;
    padding-bottom: 70px;
    padding-right: 200px;
    padding-left: 200px;
}
.checkout-price{
    border: 1px solid #D3D3D3;
    padding-top: 7px;
    padding-bottom: 7px;
    border-right: 0px;
    border-left: 0px;
    font-weight: 400;
    font-size: 25px;
    margin-top: 40px;
    margin-bottom: 30px;
}
.checkout-form-group{
    margin-bottom: 20px;
}
.checkout-inpt{
    height: 50px;
    border-radius: 1px !important;
    border: 1px solid #C5BFBF !important;
}
.checkout-form{
    margin-top: 50px;
}
.checkout-flex, .checkout-flex-card{
    display: flex;
    flex-direction: row;
}
.checkout-flex>div{
    flex: 1;
}
.cvv-group{
    margin-right: 20px;
}
.checkout-btns{
    display:flex;
    flex-direction: row;
    margin-top: 30px;
}
.checkout-btns>button{
    flex: 1;
    height: 50px;
    margin-right: 20px;
    border: 0px;
}
.checkout-submit-btn{
    background-color: black;
    color: white;
    padding-top: 7px;
    padding-bottom: 4px;
}
.checkout-cancel-btn{
    background-color: #F4F4F4;
    color: black;
}
.card-number-group{
    width: 80%;
    margin-right: 10px;
}
.paymet-processing-modal{
    padding-top: 100px;
    padding-bottom: 100px;
}
.payment-preloader{
    height: 150px;
    width: 150px;
}
.payment-processing-text{
    margin-top: 50px;
}
.payment-warning-text{
    color:#c22635;
    font-size: 13px;
}
.success-modal{
    padding-top: 100px;
    padding-bottom: 30px;
}
.cancel-success{
    margin-top: 80px;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:1115px) and (min-width:769px) {
    .checkout-modal{
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width:990px) {
    .checkout-inpt-label{
        font-size: 12px;
    }
    .cvv-group{
        margin-right: 13px;
        width: 20%;
    }
    .card-number-group{
        width: 80%;
    }
    .checkout-btns{
        display: block;
    }
    .checkout-btns>button{
        width: 100%;
        margin-bottom: 20px;
    }
    .checkout-price{
        font-size: 20px;
    }
}
@media screen and (max-width:768px) {
    #authorship-form-group{
        padding-left: 0px;
        padding-right: 0px;
    }
    .btn-IWTMMH{
       height: unset;
        padding: 10px;
    }
    .published-inpt{
        width: 70%;
       
    }
    .memorializeModal{
        margin-top: 50px !important;
    }
    .btn-copy-link{
        width: 20%;
       margin-left: 0px;
       font-size: 14px;
    }
    .published-header{
        font-size: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .share-work{
        font-size: 15px;
    }
    .days-box{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 70px;
    }
    .time{
        margin-right: 15px;
        font-size: 15px;
    }
    .published-btns{
        margin-left: 30px;
        margin-right: 30px;
    }
    .btn-view-work, .btn-memorialize{
        width: 100%;
        margin-bottom: 20px;
    }
    .checkout-modal{
        padding-left: 20px;
        padding-right: 20px;
    }
    
}