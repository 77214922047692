.haiku{
    min-height: 100vh;
    background-image: url(../../../assets/lightBg.png);
    padding-bottom: 120px;
}
.haiku-header{
    height: 70px;
    background-color: white;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 60px;
    padding-left: 80px;

}
.btn-share{
   background-color: #22ED0B;
   font-size: 15px;
   border: 0px;
   width: 100px;
   height: 100%;
}
.haiku-board{
    background-color: white;
    padding-bottom: 30px;
    max-width: 600px;
    margin: auto;
    margin-top: 70px;
}
.haiku-image{
    height: unset;
    width: 100%;
}
.haiku-text{
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 50px;
    font-size: 1.40rem;
}
.haiku-text .author {
    font-size: 1.20rem;
}
.share{
    margin-left: 5px;
}
.haiku-modal{
   
    padding-top: 60px;
    padding-bottom: 50px;
    
}
.return{
    font-size: 20px;
    font-weight: 500;
    margin-top: 50px;
    cursor: pointer;
}
.memorialize-modal-header {
}

@media screen and (max-width:768px){
    .haiku-header{
        padding-right: 30px;
        padding-left: 20px;
    }

    .haiku-text{
        padding-right: 10px;
        padding-left: 10px;
    }
    .line1-haiku, .line2-haiku,.line3-haiku{
        margin-bottom: 10px;
    }
    .haiku-board{
        margin-left: 13px;
        margin-right: 13px;
    }
    .return{
        font-size: 17px;
    }
}