.header-logo{
    /*height: 100px;*/
    /*width: 70px;*/
    width: 85px;
    cursor: pointer;
}
.page-header{
    padding-left: 40px;
    height: 80px;
    padding-right: 80px;
}
.switch-label{
    margin-top: 15px;
    margin-right: 20px;
}
.header-tab{
    font-size: 14px !important;
    font-weight: 700 !important;
}

@media screen and (max-width:768px) {
    .page-header{
        padding-right: 10px;
        padding-left: 10px;
        min-height:120px;
      
    }
    .header-logo{
        /*height: 60px;*/
        width: 85px;
        margin-top: 10px;
    }
    .switch-label{
        align-items: center !important;
        align-self: left;
        font-weight: 600;
    }
}


@media screen and (max-width:320px) {
    .header-tab{
        font-size: 11px !important;
       
    }
}

@media screen and (max-width:280px) {
    .header-tab{
        font-size: 10px !important;
        margin-right: -10px !important;
    }
}