.haiku-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* padding-left: 70px;
    padding-right: 70px; */
    margin:0 auto;
    background-color: unset;
}
.haik-card {
    /*width: 377px;*/
    width: 40%;
    height: 300px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
    padding-top: 100px;
    text-align: center;
    
    padding-left: 30px;
    padding-right: 30px;

    margin: 0 auto;
    margin-bottom: 30px;

}
.haiku-card-text{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: #000000;
}
.haiku-card-small-text{
    font-size: 14px;
    margin-top: 30px;
    color: black;
    font-weight: 500;
}

@media screen and (max-width:768px) {
    .haiku-cards{
        padding-left: 20px;
        padding-right: 20px;
    }
    .haik-card{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .haiku-card-text{
        font-size: 20px;
    }
}