.introduction{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    
}
.introduction-header{
    
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;
    letter-spacing: -0.02em;
    color: #306373;
    margin-bottom: 20px;
}
.introduction-divider{
    width: 40px;
    height: 4px;
    border: 0px;
    background-color: black;
}
.introduction-normal{
    margin-top: 20px;
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    font-style: italic !important;

}
/* .intro-grid-2, .intro-grid-1{
    border: 1px solid red;
} */
.intro-grid-1{
    padding-top: 0px !important;
}
.intro-grid-2{
    padding-top: 30px;
}
.intro-box{
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
    height: 90%;
    width: 100%;
}
.intro-box{
    padding: 30px;
}
.intro-inner-box{
    width: inherit;
    height: 100%;
  
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
    padding-top: 170px;
    padding-left: 40px;
    padding-right: 40px;
}
.intro-box-text{
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 48px;
    background: linear-gradient(275.77deg, #DDA4A8 5.96%, #272658 99.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width:768px) {
    .introduction{
        padding-left: 14px;
        padding-right: 14px;
    }
    .introduction-header{
        font-size: 38px;
    }
    .introduction-normal{
        font-size: 23px;
        line-height:35px ;
    }
    .intro-inner-box{
        height: fit-content;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .intro-box{
        height: fit-content;
    }
    .intro-box-text{
        font-size: 27px;
    }
}