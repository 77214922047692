.banner4{
    padding: 80px;
    padding-bottom: 0px;
}
.banner4-box{
    min-height: fit-content;
    padding-top: 60px;
    padding-bottom: 60px;
}
.enrinch-box{
    background: #B6EDF5;
    padding: 30px;
    width: 500px;
    height: 100% !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.enrich-header{
    font-size: 37px;
    font-weight: 400;
    margin-bottom: 20px;
}
.enrich-divider{
    border: 0px;
    width: 40px;
    background-color: black;
}
.enrich-text{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
}
.btn-enrich{
    background: rgba(255, 194, 194, 0.95);
    color: black;
    border: 0px;
    width: 200px;
    height: 55px;
    margin-top: 30px;
}
.banner4-bold{
    font-size: 35px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #BE8E9A;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media screen and (max-width:768px) {
    .banner4{
        padding-top: 40px;
        padding-left: 0px;
        padding-right: 0px;
        
    }
    .enrinch-box{
        width: 350px;
        align-self: left !important;
        margin-left: 20px;
    }
    .banner4-box{
        padding-left: 20px ;
    }
    .enrich-header{
        font-size: 30px;
    }
    .enrich-text{
        font-size: 17px;
    }
    .btn-enrich{
        width: 120px;
        height: 60px;
        font-size: 15px;
        font-weight: 500;
    }
    .banner4-bold{
        font-size: 20px;
        line-height: 30px;
    }
}