#preview-board{
    padding: 0px;
    /* min-height: 600px; */
    height: fit-content;
    padding-bottom: 20px;
}
.preview-haiku{
    overflow: hidden;
}
.review-texts{
    font-weight: 700;
    font-size: 1.40rem;
    margin-top: 20px;
    padding-bottom: 40px;
}
.review-btns{
    width: 600px;
    margin: auto;
    margin-top: 40px;
}
.btn-continue-editing, .btn-publish{
    width: 48%;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    border: 0px solid white;
}

.save-haiku-popup{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 150px;
    padding-right: 150px;
}

.save-modal-header{
    font-size: 36px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;

}
.save-modal-text1{
    font-size: 20px;
    font-weight: 500;
    margin-top: 50px;
}
.save-modal-text2{
    margin-top: 20px;
    font-size: 20px;
}
.save-haiku-form-group{
    margin-top: 50px;
    font-size: 20px;
}
.email-inpt{
    height: 40px;
    width: 100%;
    border: 1px solid lightgray;
}

.btn-continue-publish{
    width: 100%;
    height: fit-content;
    margin-top: 30px;
    background-color: black;
    border: 0px;
    color: white;
    font-size: 20px;
}
.circularProgress{
    margin-top: 30px;
}
#preview-board-img{
    width: 100%;
}

.cancel-modal{
    margin-top: 20px;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.alert{
    font-size: 14px;
}


@media screen and (max-width:768px){
    #preview-board{
        margin-left:0px !important;
        margin:auto !important;
    }
    #preview-board-img{
        /* height: 350px; */
    }
    .review-btns{
        width: 100%;
        /* margin-left: 20px; */
        padding-left: 20px;
        padding-right: 20px;
    }
    .btn-continue-editing, .btn-publish{
        width: 45%;
        font-size: 14px;
        font-weight: 600;
    }
    .save-haiku-popup{
        padding-left: 30px;
        padding-right: 30px;
    }
    .save-modal-header{
        font-size: 30px;
    }
    .save-modal-text1, .save-modal-text2{
        font-size: 15px;
    }
    .save-haiku-form-group{
        font-size: 16px;
    }
    .btn-continue-publish{
        font-size: 20px;
    }
}