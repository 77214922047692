.haiku-container{
    min-height: 100vh;
    padding-top: 60px;
    padding-bottom: 40px;
}
.image-board{
    width: 600px;
    height: fit-content;
    min-height: 300px;
    background-color: white;
    margin: auto;
   padding: 20px;
}
.apiImages{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.haiku-inputs-box{
   margin-top: 50px;
}
.haiku-form-group{
    margin-bottom: 20px;
}
.haiku-input{
    width: 500px;
    border:1px solid lightgray;
    height: 50px;
    border-radius: 5px;
}
.warning-text{
    font-size: 13px;
    font-weight: 500;
}
.haiku-input:focus{
    border: 1px solid lightgray !important;
}
.image-display{
    /* height: 500px; */
    /* overflow: scroll;   */
  
}
.img-grid{
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: scroll;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.img-grid-item{
    background-color: whitesmoke;
    height: 150px;
    width: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    overflow: hidden;
}
.btn-back, .btn-upload{
    width: 48%;
    height: 50px;
    font-size: 20px;
}
.btn-back{
    margin-right: 20px;
    background-color:#EAEAEA;
    border: 0px;
    color: black;
    font-weight: 800;
    
}
.btn-upload{
    background-color: black;
    color: white;
}
.baseBtns{
    padding-bottom: 30px;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.board-img{
    width: 100%;
    height: unset;
}
.upload-pics-header{
    font-size: 15px;
    font-weight: 700;
}
.fish{
    color: #c22635;
}
.popup-title {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
}
.search-box{
    padding-left:50px;
    padding-right:50px;
    /*padding-top: 50px;*/
    margin-top: 20px;
}
.MuiTab-wrapper {
    font-family: 'Arapey' !important;
}

@media screen and (max-width:991px) and (min-width:769px){
    .baseBtns{
        padding-left: 10px;
        padding-right: 10px;
        
    }
    .baseBtns>button{
        width: 40%;
    }
}

@media screen and (max-width:768px){
    .image-board{
        max-width:90% ;   
        margin-left: 20px;
    }
    .search-box{
        padding-left:30px;
        padding-right:30px;
        /*padding-top: 50px;*/
    }
    .haiku-container{
        padding-top: 100px;
    }
    .img-grid-item{
        height: 100px;
        width: 130px;
    }
    .img-grid{
        padding-right: 10px !important;
    }
    .haiku-input{
        width: 100%;
    }
    .haiku-inputs-box{
        padding-left: 20px;
        padding-right: 20px;
    }
    .btn-back, .btn-upload{
        width: 40%;
    }
    .baseBtns{
        padding-left: 20px;
        padding-right: 20px;
    }
}