.footer{
    /* margin-top: 30px; */
    background: #F4F4F4;
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 60px;
}
.footer-logo{
    height: 225px;
    width: 164px;
}

.footer-text{
    padding-top: 150px;
    margin-left: 60px;
}

.footer-text-item{
    font-weight: 600;
    color: black;
    margin-right: 30px;
    cursor: pointer;
}
.copy-right{
    
    font-style: italic;
    
}
.hideOnDesktop{
    display: none;
}


@media screen and (max-width:768px) {
    .hideOnMobile{
        display: none;
    }
    .hideOnDesktop{
        display: block;
    }
    .footer{
        padding-left: 20px;
        padding-bottom: 50px;
    }
    .footer-logo{
        width: 100px;
        height: 150px;
    }
    .footer-text{
        padding-top: 100px;
        margin-left: 0px;
    }
    .footer-text-item{
        font-size: 12px;
        margin-right: 7px;
    }
    .copy-right{
        margin-left: 0px !important;
        font-size: 13px;
    }
    .copy-right-mobile{
        margin-top: -20px;
    }
}