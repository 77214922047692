.banner-2{
    min-height: 300px;
    background-image: url(../../../assets/Bg.jpg);
}
.what-is-haiku{
    height: fit-content;
    margin-right: 70px;
    margin-left: 70px;
    background-color: white;
    position: relative;
    top: -180px;
    padding: 30px;
}
.lauara-img{
    height: 400px;
    width: 100%;
    margin: 0 auto;
}
.what-is-haiku-text{
   padding-left: 15px;
   padding-top: 30px;
}
.WIH-header{
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;
    letter-spacing: -0.02em;
    color: #306373;
    margin-bottom: 20px;
}
.WIH-divider{
    width: 40px;
    height: 4px;
    border: 0px;
    background-color: black;
}
.WIH-normal{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

}

@media screen and (max-width:768px) {
   .what-is-haiku{
       margin-right: 13px;
       margin-left: 13px;
       padding: 15px;
       top: -100px;
   } 
   .WIH-header{
       font-size: 35px;
       margin-top: 20px;
   }
   .WIH-normal{
       font-size: 18px;
   }
}