.banner3{
    min-height: 300px;
    background: linear-gradient(103.24deg, #232356 0.79%, #E7ABAC 101.38%);
    padding-top: 60px;
    padding-bottom: 60px;
}
.banner3-header{
    
    font-size: 50px;
    font-weight: 400;
    line-height: 63px;
    letter-spacing: -0.02em;
    color: white;
}
.banner3-divider{
    width: 50px;
}
.how-it-works{
    
    display: flex;
    flex-direction: row;
    /* padding-right: 60px; */
    /* padding-left: 60px; */
    padding-top: 60px;
    width: fit-content;
    margin:auto;
}  
/* .HIW-box{
    margin: auto;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
} */
.fab{
    border-radius: 50%;
    height: fit-content;
    width: fit-content;
    background-color: white;
    padding: 40px;
    margin: auto;
}
.HIW-vector{
    height: 50px;
    width: 50px;
}
.broken-line{
    border-bottom: 1px dashed white;
    position: relative;
    top: 120px;
    margin-left: 100px;
    margin-right: 280px;
}
.HIW-item{
    width: 170px;
    margin-right: 20px;
    /* border: 1px solid red; */
}
.banner3-divider{
    border: 0px;
    margin-top: 20px;

}
.HIW-text{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
}
.banner3-slant-text{ 
     
    font-style: italic;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 80px;
}


@media screen and (max-width:1004px) and (min-width:769px){
   .fab{
       padding: 20px;
   }
   .HIW-vector{
       height: 30px;
       width: 30px;
   }
   .HIW-text{
       font-size: 15px;
   }
   .HIW-item{
       margin-right: 3px;
       
   }
   .fab{
       margin: auto;
   }
  
}
@media screen and (max-width:880px) {
    .HIW-vector{
        height: 20px;
        width: 20px;
    }
    .fab{}
    .HIW-item{
        margin-right: 0px;
        max-width: 150px !important;
    }
    .HIW-text{
        font-size: 13px;
    }
 }





@media screen and (max-width:768px) {
    .broken-line{
        top: 80px;
        margin-left: 20px;
        /* margin-right: 40px; */
    }
    .banner3-header{
        font-size: 37px;
    }
   .HIW-vector{
       height: 20px;
       width: 20px;
   }
   .fab{
       padding: 10px;
   }
   .how-it-works{
       padding-left: 10px;
       width: initial;
   }
   .HIW-item{
       margin-right: 10px;
   }
   .HIW-text{
       font-size: 12px;
       line-height: 17px;
   }
   .banner3-slant-text{
       font-size: 23px;
       padding-left: 20px;
       padding-right: 20px;
       line-height: 30px;
   }
} 

@media screen and (max-width:350px) {
    .HIW-item{
        margin-right: 5px;
    }
}

@media screen and (max-width:300px) {
    .HIW-text{
        font-size: 10px;
    }
}